export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'zh';
export const localeOptions = [
  { id: 'zh', name: 'Chinese', direction: 'ltr' },
  { id: 'en', name: 'English', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};
console.log('envreached');
console.log('processenv', process.env);
export const servicePath = process.env.SP_API_HOST;
export const magentoPath = process.env.SP_MAGENTO_HOST;
export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const currentUser = {
  id: 1,
  title: 1,
  img: '/assets/img/profiles/l-1.jpg',
  date: '',
  role: UserRole.Admin,
};
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.greenlime';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const octaUrl = process.env.SP_OCTA_URL;

export const OctaReportChinese = {
  'Vendor Name': '供应商名字',
  'Vendor SKU': '供应商款号',
  Fabric: '面料',
  Color: '颜色',
  'Fit Type': '板型',
  'Model Code': 'Model Code',
  'HS Code': 'HS Code',
  Category: '类别',
  Brand: '品牌',
  Material: '面料',
  'Material Composition': '面料成分',
  'Fabric Type Composition': '实测面料',
  'Knitted/Woven': '针织/梭织',
  'Seam Slippage': '骨位撕拉力',
  'Measuring Method': '量法',
  'Vendor Record': '工厂记录',
  'Styli Record': 'QA记录1',
  'Styli Record 2': 'QA记录2',
  Suggestion: 'QA建议尺寸',
};
